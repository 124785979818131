import isNumber from "lodash/isNumber";
import TextField from "components/TextField";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { eligibleForSubmissionPropTypes } from "../propTypes";

const translate = initTranslationsHelper("universal.custom_forms.number_control");

const NumberControl = ({ schema, onChange, value, error, disabled }) => {
  const { label, description, placeholder, required, name, min, max, step } = schema;

  return (
    <TextField
      disabled={disabled}
      error={error}
      fullWidth
      helperText={description}
      inputProps={{ min, max, step }}
      label={label}
      name={name}
      onChange={(e) => onChange(parseValue(e.target.value))}
      placeholder={placeholder}
      required={required}
      type="number"
      value={value}
    />
  );
};

NumberControl.eligibleForSubmission = true;

NumberControl.getValueFromSchema = (schema) => parseValue(schema.value);

NumberControl.getUpdatedSchemaFromValue = (schema, value) => ({
  ...schema,
  value,
});

NumberControl.validate = (schema, value) => {
  const { required, max, min, step, label } = schema;

  if (value && !isNumber(value)) {
    return translate("error_not_a_number", { field: label });
  }
  if (required && !value) {
    return translate("error_required", { field: label });
  }
  if (isNumber(value)) {
    if (isNumber(min) && value < min) {
      return translate("error_min", { field: label, min });
    }
    if (isNumber(max) && value > max) {
      return translate("error_max", { field: label, max });
    }
    if (isNumber(step) && !isStepValid(value, step, min)) {
      return translate("error_step", { field: label, min });
    }
  }

  return undefined;
};

NumberControl.propTypes = eligibleForSubmissionPropTypes;

export default NumberControl;

const parseValue = (value) => {
  if (value) {
    return Number.parseFloat(value);
  }

  return "";
};

const isStepValid = (value, step, base = 0) => (value - base) % step === 0;
