import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import getPainDiagramConfig from "./getPainDiagramConfig";
import styles from "./styles.module.scss";

const PainDiagramPreview = ({ painDiagramType, painDiagramData }) => {
  const painDiagramTypeConfig = getPainDiagramConfig(painDiagramType);
  const aspectRatio = painDiagramTypeConfig.width / painDiagramTypeConfig.height;

  return (
    <Box>
      <Box
        className={styles[painDiagramTypeConfig.className]}
        sx={{
          aspectRatio,
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        {painDiagramData && <img alt={painDiagramType} src={painDiagramData} style={{ width: "100%" }} />}
      </Box>
    </Box>
  );
};

export default PainDiagramPreview;

PainDiagramPreview.propTypes = {
  painDiagramData: PropTypes.string,
  painDiagramType: PropTypes.string.isRequired,
};

PainDiagramPreview.defaultProps = {
  painDiagramData: null,
};
