function getErrors(schema, components, values) {
  const { controls } = schema;

  return controls.reduce((errorsSoFar, controlSchema) => {
    const { type, name } = controlSchema;

    const Component = components[type];
    if (!Component || !Component.eligibleForSubmission) {
      return errorsSoFar;
    }
    const value = values[name];
    const error = Component.validate(controlSchema, value);
    if (!error) {
      return errorsSoFar;
    }
    return {
      ...errorsSoFar,
      [name]: error,
    };
  }, {});
}

export default getErrors;
