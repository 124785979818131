import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import clsx from "clsx";
import styles from "./styles.module.scss";

const Title = ({ children, className, ...props }) => (
  <Box className={clsx(styles.title, className)} component="h1" p={2} {...props}>
    {children}
  </Box>
);

Title.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
};

Title.defaultProps = {
  children: null,
  className: null,
};

export default Title;
