import PropTypes from "prop-types";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import FormHelperText from "@mui/material/FormHelperText";

/* maybe there's a better way so we don't need this rule */

const RadioButtonList = ({ error, inline, name, onChoose, options, selectedOption }) => (
  <>
    <RadioGroup aria-labelledby={name} name={name} onChange={onChoose} row={inline} value={selectedOption}>
      {options.map(({ value, label, disabled, note }) => {
        const id = `${name}-${value}`;
        const currentOptionIsSelected = value === selectedOption;

        return (
          <Box key={id}>
            <FormControlLabel
              control={<Radio disableRipple />}
              disabled={disabled}
              label={label}
              sx={{
                alignItems: "flex-start",
              }}
              value={value}
            />
            {note && currentOptionIsSelected && (
              <Alert icon={false} severity="info" sx={{ typography: "caption" }} variant="outlined">
                {note.title && (
                  <AlertTitle sx={{ display: "inline", typography: "caption", fontWeight: 700 }}>
                    {note.title}
                  </AlertTitle>
                )}
                {note.description}
              </Alert>
            )}
          </Box>
        );
      })}
    </RadioGroup>
    {error && <FormHelperText error>{error}</FormHelperText>}
  </>
);

RadioButtonList.propTypes = {
  error: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  /** the action when a radio button is chosen */
  onChoose: PropTypes.func,
  /** the list of options */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      note: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  /** the option that should be checked */
  selectedOption: PropTypes.string,
};

RadioButtonList.defaultProps = {
  error: undefined,
  inline: false,
  onChoose: () => {},
  selectedOption: "",
};

export default RadioButtonList;
