import requestToServer from "utils/requestToServer";

const saveResponse = ({ action, method, offerId, values }) => {
  const valuesToSubmit = cleanValues(values);

  requestToServer({
    path: action,
    body: {
      response: {
        ...(valuesToSubmit && { data: valuesToSubmit }),
        offer_id: offerId.toString(),
      },
    },
    method,
  });
};

const cleanValues = (obj) => {
  const objectWithNonEmptyArrayValues = Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => {
      if (Array.isArray(v)) return v.length > 0;
      return true;
    }),
  );
  if (Object.keys(objectWithNonEmptyArrayValues).length === 0) return null;

  return objectWithNonEmptyArrayValues;
};

export default saveResponse;
