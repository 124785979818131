import * as React from "react";
import ReactDOM from "react-dom";
import { withI18nContext } from "hooks/useTranslation";
import { withMuiTheme } from "theme/utils";
import muiTheme from "theme/muiTheme";
import setCssVariables from "theme/setCssVariables";
import decorateComponents from "utils/decorateComponents";
import getEnv from "utils/getEnv";
import "./styles.scss";

const REACT_COMPONENT_NAME_ATTR = "data-component-name";
const REACT_PROPS_ATTR = "data-props";
const stylesheetPackLinks = getEnv("stylesheetPackLinks");

class ReactElement extends HTMLElement {
  static get observedAttributes() {
    return [REACT_COMPONENT_NAME_ATTR, REACT_PROPS_ATTR];
  }

  constructor() {
    super();

    const shadow = this.attachShadow({ mode: "open" });

    if (stylesheetPackLinks?.length) {
      stylesheetPackLinks.forEach((stylesheetPackLink) => {
        const linkElem = document.createElement("link");
        linkElem.setAttribute("rel", "stylesheet");
        linkElem.setAttribute("href", stylesheetPackLink);
        shadow.appendChild(linkElem);
      });
    }

    const wrapper = document.createElement("div");

    // Hide the element until the styles are loaded
    wrapper.style.opacity = "0";

    wrapper.setAttribute("class", "react-element-wrapper");
    shadow.appendChild(wrapper);
  }

  connectedCallback() {
    createReactElement(this);
  }
}

const createReactElement = (container) => {
  const componentName = container.getAttribute(REACT_COMPONENT_NAME_ATTR);

  if (!componentName) return;

  const component = ReactElement.components[componentName];

  if (component) {
    const decoratedComponent = decorateComponents({ [componentName]: component }, [
      withMuiTheme(muiTheme, container.shadowRoot),
      withI18nContext,
    ])[componentName];
    const propsJson = container.getAttribute(REACT_PROPS_ATTR);
    const props = JSON.parse(propsJson);
    const reactElement = React.createElement(decoratedComponent, props);

    const reactElementWrapper = container.shadowRoot.querySelector(".react-element-wrapper");
    setCssVariables(muiTheme, reactElementWrapper);
    ReactDOM.render(reactElement, reactElementWrapper);
  } else {
    throw new Error(`No react component with the name ${componentName} could be found in this context.`);
  }
};

const renderReactElements = (components) => {
  ReactElement.components = components;
  customElements.define("react-element", ReactElement);
};

export default renderReactElements;
