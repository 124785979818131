import { useState } from "react";
import Link from "@mui/material/Link";
import { trackEvent, eventNames } from "utils/EventsTracking";
import useTranslation from "hooks/useTranslation";
import ItemsList from "components/ItemsList";
import AgreementModal from "./AgreementModal";

const ListDisplay = ({ handleClick, text }) => <ItemsList items={[{ onClick: handleClick, text }]} />;

const ButtonDisplay = ({ handleClick, text }) => (
  <Link
    className="agreementButton"
    component="button"
    onClick={handleClick}
    style={{ height: "1rem", textAlign: "left" }}
    type="button"
    variant="body2"
  >
    {text}
  </Link>
);

const modalLink = {
  button: ButtonDisplay,
  list: ListDisplay,
};

const EpionAuthorizationModal = ({ eventTracking = true, epionAuthModalProperties, type }) => {
  const [open, setOpen] = useState(false);
  const t = useTranslation("universal.epion_authorization");
  const ModalLink = modalLink[type];

  const handleClick = () => {
    setOpen(true);
    if (eventTracking) {
      trackEvent(eventNames.EPION_AUTHORIZATION_OPENED);
    }
  };

  return (
    <>
      <ModalLink handleClick={handleClick} text={t("epion_authorization")} />
      <AgreementModal epionAuthModalProperties={epionAuthModalProperties} open={open} setOpen={setOpen} />
    </>
  );
};

export default EpionAuthorizationModal;
