import { Box, Typography } from "@mui/material";
import FormField from "components/FormField";
import RadioButtonList from "components/RadioButtonList";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { eligibleForSubmissionPropTypes } from "../propTypes";

const translate = initTranslationsHelper("universal.custom_forms.radiobutton_group_control");

const RadioButtonGroupControl = ({ schema, onChange, error, value, disabled }) => {
  const { label, name, required, values: options } = schema;

  const radiobuttonListOptions = options.map((option) => {
    const radioButtonLabel = option.secondaryText ? (
      <>
        <Typography>{option.label}</Typography>
        <Typography fontSize="0.75rem">{option.secondaryText}</Typography>
      </>
    ) : (
      option.label
    );

    return {
      label: radioButtonLabel,
      value: option.value,
      selected: value === option.value,
      name,
      disabled,
    };
  });

  const handleChoose = (event) => onChange(event.target.value);

  return (
    <FormField disabled={disabled} error={error} fullWidth label={label} required={required}>
      <Box pt={1}>
        <RadioButtonList name={name} onChoose={handleChoose} options={radiobuttonListOptions} selectedOption={value} />
      </Box>
    </FormField>
  );
};

RadioButtonGroupControl.eligibleForSubmission = true;

RadioButtonGroupControl.getValueFromSchema = ({ values: options }) => {
  const selected = options.find((option) => option.selected);

  if (selected) {
    return selected.value;
  }

  return "";
};

RadioButtonGroupControl.getUpdatedSchemaFromValue = (schema, selectedValues) => {
  const { values: options } = schema;
  const optionsUpdated = options.map((optionValue) => ({
    ...optionValue,
    selected: selectedValues.includes(optionValue.value),
  }));

  return {
    ...schema,
    values: optionsUpdated,
  };
};

RadioButtonGroupControl.validate = (schema, selectedValue) => {
  const { required, label } = schema;
  if (required && !selectedValue) {
    return translate("error_required", { field: label });
  }

  return undefined;
};

RadioButtonGroupControl.propTypes = eligibleForSubmissionPropTypes;

export default RadioButtonGroupControl;
