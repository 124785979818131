import SignaturePad from "signature_pad";

const penColor = "#3b73b9";

function createPainDiagram(canvas) {
  return new SignaturePad(canvas, {
    penColor,
  });
}

export default createPainDiagram;
