import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Modal from "components/Modal";
import ModalCarousel from "components/Modal/ModalCarousel";
import useTranslation from "hooks/useTranslation";
import TermsOfUseEpion from "previsit/TermsOfUse/Agreements/TermsOfUseEpion";
import PrivacyStatement from "previsit/TermsOfUse/Agreements/PrivacyStatement";

const Agreements = ({
  epionTermsStandaloneUrl,
  epionTermsVersion,
  privacyPolicyStandaloneUrl,
  privacyPolicyVersion,
  translationKeys,
}) => {
  const [currentItem, setCurrentItem] = useState(null);
  const [termsOfUseOpen, setTermsOfUseOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const translate = useTranslation("universal.agreements");

  useEffect(() => {
    if (termsOfUseOpen && currentItem === null) {
      setCurrentItem(0);
    } else if (!termsOfUseOpen && currentItem !== null) {
      setCurrentItem(null);
    }
  }, [currentItem, termsOfUseOpen]);

  const goToPageTwo = () => {
    setCurrentItem(1);
  };

  const goToPageOne = () => setCurrentItem(0);

  return (
    <Box pt={{ xs: 2 }}>
      {translate(translationKeys[0])}
      <TermsLink linkText={translate("terms_of_use")} onClick={() => setTermsOfUseOpen(true)} />
      {translate(translationKeys[1])}
      <TermsLink linkText={translate("privacy_policy")} onClick={() => setPrivacyPolicyOpen(true)} />
      {translate(translationKeys[2])}
      <ModalCarousel
        currentItem={currentItem}
        modals={[
          <TermsOfUseEpionModal
            epionTermsStandaloneUrl={epionTermsStandaloneUrl}
            epionTermsVersion={epionTermsVersion}
            goToPageTwo={goToPageTwo}
            key="one"
            onDoneButtonClick={() => setTermsOfUseOpen(false)}
            open={termsOfUseOpen}
          />,
          <PrivacyStatementModal
            cancelButtonText={translate("back")}
            key="two"
            onCancelButtonClick={goToPageOne}
            onDoneButtonClick={() => setTermsOfUseOpen(false)}
            open={termsOfUseOpen}
            privacyPolicyStandaloneUrl={privacyPolicyStandaloneUrl}
            privacyPolicyVersion={privacyPolicyVersion}
          />,
        ]}
      />
      <PrivacyStatementModal
        onDoneButtonClick={() => setPrivacyPolicyOpen(false)}
        open={privacyPolicyOpen}
        privacyPolicyStandaloneUrl={privacyPolicyStandaloneUrl}
        privacyPolicyVersion={privacyPolicyVersion}
      />
    </Box>
  );
};

const TermsLink = ({ linkText, onClick }) => (
  <>
    <Link
      component="button"
      onClick={onClick}
      sx={{ height: "1rem" }}
      type="button"
      variant="body2"
    >
      {linkText}
    </Link>
    {" "}
  </>
);

const TermsOfUseEpionModal = ({
  epionTermsStandaloneUrl,
  epionTermsVersion,
  goToPageTwo,
  onDoneButtonClick,
  open,
}) => {
  const translate = useTranslation("universal.agreements");

  return (
    <Modal
      disableBackdropClick
      doneButtonText={translate("done")}
      onDoneButtonClick={onDoneButtonClick}
      open={open}
    >
      <TermsOfUseEpion
        epionTermsStandaloneUrl={epionTermsStandaloneUrl}
        epionTermsVersion={epionTermsVersion}
        goToPageTwo={goToPageTwo}
      />
    </Modal>
  );
};

const PrivacyStatementModal = ({
  cancelButtonText,
  onCancelButtonClick,
  onDoneButtonClick,
  open,
  privacyPolicyStandaloneUrl,
  privacyPolicyVersion,
}) => {
  const translate = useTranslation("universal.agreements");

  return (
    <Modal
      cancelButtonText={cancelButtonText}
      disableBackdropClick
      doneButtonText={translate("done")}
      onCancelButtonClick={onCancelButtonClick}
      onDoneButtonClick={onDoneButtonClick}
      open={open}
    >
      <PrivacyStatement
        privacyPolicyStandaloneUrl={privacyPolicyStandaloneUrl}
        privacyPolicyVersion={privacyPolicyVersion}
      />
    </Modal>
  );
};

Agreements.defaultProps = {
  translationKeys: [
    "by_accepting_this_offer",
    "and_acknowledge_the",
    "of_epion_health",
  ],
};

export default Agreements;
