import PropTypes from "prop-types";

export const basePropTypes = {
  schema: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
};

export const eligibleForSubmissionPropTypes = {
  ...basePropTypes,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};
