import SectionDivider from "components/SectionDivider";
import { basePropTypes } from "../propTypes";

const SectionDividerControl = () => <SectionDivider />;

SectionDividerControl.eligibleForSubmission = false;

SectionDividerControl.propTypes = basePropTypes;

export default SectionDividerControl;
