import RawHTML from "components/RawHTML";
import { Box } from "@mui/material";
import useTranslation from "hooks/useTranslation";

const AgreementText = ({ version }) => {
  const t = useTranslation("universal.epion_authorization.agreement");

  return (
    <Box sx={{ textAlign: "left" }}>
      <RawHTML component="p">{t(`${version}.paragraph_1_html`)}</RawHTML>
      <RawHTML component="p">{t(`${version}.paragraph_2_html`)}</RawHTML>
      <RawHTML component="p">{t(`${version}.paragraph_3_html`)}</RawHTML>
      <RawHTML component="p">{t(`${version}.paragraph_4_html`)}</RawHTML>
      <ul>
        <RawHTML component="li">{t(`${version}.list_item_1_html`)}</RawHTML>
        <RawHTML component="li">{t(`${version}.list_item_2_html`)}</RawHTML>
        <RawHTML component="li">{t(`${version}.list_item_3_html`)}</RawHTML>
        <RawHTML component="li">{t(`${version}.list_item_4_html`)}</RawHTML>
        <RawHTML component="li">{t(`${version}.list_item_5_html`)}</RawHTML>
      </ul>
      <RawHTML component="p">{t(`${version}.paragraph_5_html`)}</RawHTML>
    </Box>
  );
};

export default AgreementText;
