const isClickedValueWithMessage = (props, propName, componentName) => {
  const { message, clickedValue } = props;
  const { [propName]: content } = props;
  const isStringOrUndefined = ["string", "undefined"].includes(typeof clickedValue);

  const invalidProp = `Invalid prop '${propName}' with content: '${content}' ` + `supplied to ${componentName}.`;

  if (clickedValue && !message) {
    return new Error(`${invalidProp} Prop 'clickedValue' expected in ` + "conjunction with a 'message' prop");
  }

  if (!isStringOrUndefined) {
    return new Error(`${invalidProp} Expected 'string' or 'undefined'`);
  }

  return null;
};

export { isClickedValueWithMessage };
