import PropTypes from "prop-types";
import OneColumnGrid from "components/OneColumnGrid";
import Page from "components/Page";
import LogoContainer from "previsit/CustomForm/Components/LogoContainer";
import Controls from "../Controls";
import { basePropTypes, eligibleForSubmissionPropTypes } from "../Components/propTypes";

const CustomFormScreen = ({
  components,
  customFormVariables,
  disabled,
  errors,
  infoMessage,
  logoAlt,
  logoSrc,
  name,
  onControlBlur,
  onControlChange,
  onSubmit,
  pageNoteContent,
  schema,
  submitButtonText,
  isSubmitting,
  titleVariant,
  values,
}) => {
  const { controls } = schema;

  return (
    <Page>
      {!!infoMessage && <Page.Alert severity="info">{infoMessage}</Page.Alert>}

      <Page.Title text={name} variant={titleVariant}>
        {logoSrc || logoAlt ? <LogoContainer alt={logoAlt} src={logoSrc} /> : ""}
      </Page.Title>

      <Page.Content>
        <OneColumnGrid>
          <Controls
            components={components}
            controls={controls}
            customFormVariables={customFormVariables}
            disabled={disabled}
            errors={errors}
            onControlBlur={onControlBlur}
            onControlChange={onControlChange}
            values={values}
          />
        </OneColumnGrid>
      </Page.Content>

      <Page.ButtonPrimary disabled={isSubmitting} loading={isSubmitting} onClick={onSubmit}>
        {submitButtonText}
      </Page.ButtonPrimary>

      {pageNoteContent && (
        <Page.Note align="left" light={false} placement="bottom">
          <OneColumnGrid>{pageNoteContent}</OneColumnGrid>
        </Page.Note>
      )}
    </Page>
  );
};

CustomFormScreen.propTypes = {
  components: PropTypes.objectOf(PropTypes.elementType).isRequired,
  customFormVariables: PropTypes.shape({}),
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  infoMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false]), PropTypes.element]),
  logoAlt: PropTypes.string,
  logoSrc: PropTypes.string,
  name: PropTypes.string.isRequired,
  onControlBlur: PropTypes.func,
  onControlChange: PropTypes.func,
  onSubmit: PropTypes.func,
  pageNoteContent: PropTypes.node,
  schema: PropTypes.shape({
    controls: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.shape(basePropTypes), PropTypes.shape(eligibleForSubmissionPropTypes)]).isRequired,
    ),
  }).isRequired,
  submitButtonText: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  titleVariant: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])).isRequired,
};

CustomFormScreen.defaultProps = {
  customFormVariables: {},
  disabled: undefined,
  infoMessage: undefined,
  logoAlt: undefined,
  logoSrc: undefined,
  onControlBlur: () => {},
  onControlChange: () => {},
  onSubmit: () => {},
  pageNoteContent: undefined,
  submitButtonText: "Save",
  titleVariant: undefined,
};

export default CustomFormScreen;
