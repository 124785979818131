function getUpdatedSchemaFromValues(schema, components, values) {
  const { controls } = schema;

  const newControls = controls.map((control) => {
    const { type, name } = control;
    const Component = components[type];
    if (!Component || !Component.eligibleForSubmission) {
      return control;
    }
    const value = values[name];
    const newControl = Component.getUpdatedSchemaFromValue(control, value);
    return newControl;
  });

  return {
    controls: newControls,
  };
}

export default getUpdatedSchemaFromValues;
