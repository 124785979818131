import PropTypes from "prop-types";

const RawHTML = ({ component: Component, children, ...props }) => (
  <Component
    dangerouslySetInnerHTML={{
      __html: children,
    }}
    {...props}
  />
);

RawHTML.propTypes = {
  component: PropTypes.string,
  children: PropTypes.string.isRequired,
};

RawHTML.defaultProps = {
  component: "div",
};

export default RawHTML;
