import { basePropTypes } from "../propTypes";

const HeaderControl = ({ schema }) => {
  const { subtype: Heading, label } = schema;

  return <Heading>{label}</Heading>;
};

HeaderControl.eligibleForSubmission = false;

HeaderControl.propTypes = basePropTypes;

export default HeaderControl;
