import styles from "components/Page/styles.module.scss";
import Container from "components/Container";
import PropTypes from "prop-types";

const LogoContainer = ({ src, alt, sx }) => (
  <Container sx={sx} textAlign="center">
    <img alt={alt} className={styles.logo} src={src} />
  </Container>
);

LogoContainer.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,

  sx: PropTypes.object,
};

LogoContainer.defaultProps = {
  sx: { my: 2, pt: 2 },
};

export default LogoContainer;
