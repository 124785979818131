import { useEffect, useState } from "react";
import { Document, Page as PdfPage } from "react-pdf";
import { Box, Typography } from "@mui/material";
import LogoContainer from "previsit/CustomForm/Components/LogoContainer";
import Page from "components/Page";
import requestToServer from "utils/requestToServer";
import initTranslationsHelper from "utils/initTranslationsHelper";
import { trackEvent, eventNames } from "utils/EventsTracking";
import OneColumnGrid from "components/OneColumnGrid";

const t = initTranslationsHelper("universal.eps.practice_promo");

const PracticePromo = ({
  action,
  data: { id, callToActionText, disclaimer, logo, newTabUrl, pdfUrl, stepName },
  method,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmission = () => {
    setIsSubmitting(true);
    trackEvent(eventNames.EPS_PRACTICE_PROMO_COMPLETED, { practice_promo_id: id });
    requestToServer({ path: action, body: {}, method });
  };
  const handleOpenNewTab = () => {
    window.open(newTabUrl, `_eps_practice_promo_${id}`).focus();
    trackEvent(eventNames.EPS_PRACTICE_PROMO_CTA_CLICKED, { practice_promo_id: id });
    handleSubmission();
  };

  const primaryButtonProps = {};
  if (newTabUrl) {
    primaryButtonProps.onClick = handleOpenNewTab;
    primaryButtonProps.text = callToActionText || t("learn_more");
  } else {
    primaryButtonProps.onClick = handleSubmission;
    primaryButtonProps.text = t("continue");
  }

  useEffect(() => {
    trackEvent(eventNames.EPS_PRACTICE_PROMO_PRESENTED, { practice_promo_id: id });
  }, [id]);

  return (
    <Page>
      <Page.Layout columnConstraints={Page.DefaultColumnConstraints} />

      {(stepName || logo) && (
        <Page.Title text={stepName}>
          {logo ? <LogoContainer alt={logo.alt} src={logo.src} sx={{ mt: stepName ? 2 : 0 }} /> : ""}
        </Page.Title>
      )}

      <Page.Content display="flex">
        <Pdf src={pdfUrl} />
      </Page.Content>

      <Page.ButtonPrimary loading={isSubmitting} onClick={primaryButtonProps.onClick}>
        {primaryButtonProps.text}
      </Page.ButtonPrimary>
      {newTabUrl && (
        <Page.ButtonTertiary disabled={isSubmitting} onClick={handleSubmission} variant="outlined">
          {t("skip")}
        </Page.ButtonTertiary>
      )}

      {disclaimer && (
        <Page.Note placement="bottom">
          <OneColumnGrid sx={{ mt: -0.5 }}>
            <Typography variant="caption">{disclaimer}</Typography>
          </OneColumnGrid>
        </Page.Note>
      )}
    </Page>
  );
};

const Pdf = ({ src }) => (
  <Document error={t("pdf_load_failure")} file={src}>
    <Box
      sx={{
        "& canvas": {
          width: "100% !important",
          height: "auto !important",
        },
      }}
    >
      <PdfPage pageNumber={1} />
    </Box>
  </Document>
);

export default PracticePromo;
