/**
***************************************************************
***************************************************************
** IF YOU MODIFY THE CONTENT OF THIS DOCUMENT, PLEASE UPDATE **
** THE VERSION NUMBER FOR `epion_patient_terms_of_use` in    **
** app/models/agreement.rb                                   **
***************************************************************
***************************************************************
*/
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.scss";

const TermsOfUseEpion = ({ epionTermsVersion, goToPageTwo, epionTermsStandaloneUrl }) => (
  <div>
    {
      epionTermsStandaloneUrl && (
        <p>
          This agreement can also be viewed, printed, and downloaded at:
          {" "}
          <Link href={epionTermsStandaloneUrl} rel="noreferrer" target="_blank">{epionTermsStandaloneUrl}</Link>
        </p>
      )
    }
    <Box component="header" mb={4} mt={2}>
      <Typography component="h1" variant="h5">TERMS OF USE</Typography>
      <Typography component="h2" variant="h5">{epionTermsVersion}</Typography>
    </Box>
    <p className={styles.border}>
      <b>
        <span>
          NOTE: You are about to access features of this check-in platform that are provided by Epion Health, Inc.,
          rather than the health care provider group with which you are checking in for an appointment. You can
          distinguish those features by the use of the term ‘Epion Patient Services’ or similar indication associated
          with the feature. The terms and conditions below govern your use of the platform with respect to these
          features only (and the Terms of Use you accepted with the health care provider group govern all other use of
          this platform).
          {" "}
        </span>
      </b>
    </p>

    <p>
      <b>
        <i>
          This is a legally binding agreement. Please read these terms and
          conditions carefully. By using the features described above, you
          acknowledge your agreement to be bound by the terms and conditions of
          this agreement. If you do not agree to these terms and conditions,
          you cannot use these features.
        </i>
      </b>
    </p>
    <p>
      <span>
        This agreement states the terms and conditions for your use of the
        features described above of the software application made available
        through this tablet or website (the “
        <b>Features</b>
        ”)
        provided to you by Epion Health, Inc. (“
        <b>Epion</b>
        ”). Please read these
        terms carefully.
      </span>
    </p>
    <p>
      <span>
        The Features are not intended to be a substitute for professional
        medical advice, diagnosis, or treatment, and the information in the
        Features may not be complete or accurate. Always seek the advice of your
        physician or other qualified health care provider with any questions you
        may have regarding a health condition. Never disregard professional
        medical advice or delay in seeking it because of something you have seen
        in the Features.
        <b>
          <u>
            If you think you may have a medical emergency, dial 911, or, if you
            are in the office of your health care provider, see the reception
            desk immediately.
          </u>
        </b>
      </span>
    </p>
    <p>
      <span>
        Epion hereby grants to you a personal, non-exclusive, non-transferable,
        non-sublicensable, revocable license to use the Features only as
        specifically set forth in this agreement. Epion reserves for itself and
        its licensors any rights not expressly granted in this agreement.
      </span>
    </p>
    <p>
      <span>
        You acknowledge that the Features are provided to you free of charge and
        Epion may terminate this agreement and the license granted in this
        agreement at any time with or without notice to you by disabling your
        access to the Features. You may terminate this agreement at any time
        upon written notice to Epion.
      </span>
    </p>
    <p>
      <span>
        You acknowledge
        {" "}
        <Link
          component="button"
          onClick={() => goToPageTwo("privacyStatement")}
          type="button"
          variant="body2"
        >
          Epion’s Privacy Policy
        </Link>
        , which addresses the information Epion may collect from or about you
        and how Epion may use and disclose such information. Epion also may
        compile statistical data relating to your use of the Features so long as
        such statistical data does not identify you, and you hereby assign to
        Epion any and all of your right, title, and interest in and to such
        statistical data.
      </span>
    </p>
    <p>
      <span>
        You acknowledge and agree that you do not acquire any ownership rights
        to the Features through this agreement or by your use of the Features.
        Epion and/or its licensors have and retain exclusive, valid, and
        non-contestable ownership of the Features and all intellectual property
        and proprietary rights therein. You acknowledge that you may suggest
        improvements and/or communicate to Epion ideas, inventions, discoveries,
        or concepts (“
        <b>Ideas</b>
        ”) related to the Features and that you and Epion may discover or create
        the Ideas jointly, and you agree that any such Idea shall be and remain
        solely the property of Epion and/or its licensors and may be used and
        sold, licensed, or otherwise provided by Epion and/or its licensors to
        third parties, or published or otherwise publicly disclosed, in Epion’s
        and/or its licensors’ sole discretion without notice, attribution,
        payment of royalties, or liability to you. You hereby assign to Epion
        any and all of your right, title, and interest in and to any such Ideas.
      </span>
    </p>
    <p>
      <span>
        You acknowledge that the Features constitute valuable assets and trade
        secrets of Epion and/or its licensors and that the Features are
        protected by United States copyright law and international treaty
        provisions. You may not copy, modify, adapt, translate into any
        language, distribute, or create derivative works based on the Features.
        {" "}
      </span>
    </p>
    <p>
      <span>
        You may not do any of the following: (i) rent, lease, resell, assign, or
        otherwise transfer rights or access to the Features; (ii) distribute,
        copy, reproduce, display, republish, or transmit any portion of the
        Features; (iii) modify, translate, reverse engineer, decompile,
        disassemble, or create derivative works based upon the Features or any
        software or programming related thereto; (iv)&nbsp;“frame” or “mirror”
        any portion of the Features; (v) use any robot, spider, other automatic
        device, or manual process, to “screen scrape,” monitor, “mine,” or copy
        any portion of the Features; use any device, software, methodology, or
        routine to interfere with the proper working of the Features or servers
        or networks connected to the Features; (vi) access data of which you are
        not an intended recipient or log into a server or account on a network
        related to the Features that you are not expressly authorized to access;
        (vii) harvest or collect information about other Features users; (viii)
        restrict or inhibit any other person from using the Features, including
        by means of “hacking” or defacing any portion of the Features; or (ix)
        attempt to do, or assist anyone else with doing or attempting, any of
        the foregoing.
      </span>
    </p>
    <p>
      <span>
        Laws regarding the transmission of personal information may vary from
        one state to another in the United States and from one country to
        another. You agree that you will use the Features only in a manner that
        complies with all applicable laws in the jurisdictions in which you use
        the Features and that your use of the Features is subject to all
        applicable local, state, national, and international laws and
        regulations. You further agree to access the Features only from within
        the United States.
      </span>
    </p>
    <p>
      <span>
        THE FEATURES AND ALL SERVICES PROVIDED OR TO BE PROVIDED UNDER THIS
        AGREEMENT ARE PROVIDED “AS IS,” AND YOU ASSUME THE ENTIRE RISK AS TO THE
        QUALITY AND PERFORMANCE OF THE FEATURES. EPION, FOR ITSELF AND ITS
        LICENSORS, DISCLAIMS, ANY AND ALL WARRANTIES, CONDITIONS, OR
        REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN) WITH RESPECT TO
        THE FEATURES OR ANY PART OF IT, INCLUDING ANY AND ALL IMPLIED WARRANTIES
        OR CONDITIONS OF TITLE, NONINFRINGEMENT, MERCHANTABILITY, OR FITNESS OR
        SUITABILITY FOR ANY PURPOSE (WHETHER OR NOT EPION KNOWS, HAS REASON TO
        KNOW, HAS BEEN ADVISED, OR OTHERWISE IS IN FACT AWARE OF ANY SUCH
        PURPOSE), WHETHER ALLEGED TO ARISE BY LAW, BY REASON OF CUSTOM OR USAGE
        IN THE TRADE, BY COURSE OF DEALING, OR OTHERWISE.
      </span>
    </p>
    <p>
      <span>
        NEITHER EPION NOR ITS LICENSORS SHALL BE LIABLE TO YOU (NOR TO ANY
        PERSON CLAIMING RIGHTS DERIVED FROM THE YOUR RIGHTS) FOR INCIDENTAL,
        INDIRECT, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY
        KIND (INCLUDING LOST PROFITS, LOSS OF OR DAMAGE TO DATA, LOSS OF
        BUSINESS, OR OTHER ECONOMIC DAMAGE), WHETHER ARISING IN CONTRACT, TORT
        (INCLUDING NEGLIGENCE) OR OTHERWISE, AND REGARDLESS OF WHETHER EPION WAS
        ADVISED, HAD OTHER REASON TO KNOW, SHOULD HAVE ANTICIPATED, OR IN FACT
        KNEW OF THE POSSIBILITY THEREOF. THE FEATURES ARE PROVIDED TO YOU FREE
        OF CHARGE, AND IN NO EVENT SHALL EPION’S OR ITS LICENSORS’ AGGREGATE
        LIABILITY TO YOU (INCLUDING LIABILITY TO ANY PERSON OR PERSONS WHOSE
        CLAIM OR CLAIMS ARE BASED ON OR DERIVED FROM A RIGHT OR RIGHTS CLAIMED
        BY OR THROUGH YOU), WITH RESPECT TO ANY AND ALL CLAIMS AT ANY AND ALL
        TIMES ARISING FROM OR RELATED TO THE SUBJECT MATTER OF THIS AGREEMENT
        (INCLUDING WITHOUT LIMITATION THE FEATURES AND SERVICES), IN CONTRACT,
        WARRANTY, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED $50. THE
        PROVISIONS OF THIS PARAGRAPH ARE INDEPENDENT OF, SEVERABLE FROM, AND TO
        BE ENFORCED INDEPENDENTLY OF ANY OTHER ENFORCEABLE OR UNENFORCEABLE
        PROVISION OF THIS AGREEMENT.
      </span>
    </p>
    <p>
      <span>
        Some jurisdictions do not allow the exclusion of certain warranties or
        the limitation or exclusion of liability for incidental or consequential
        damages, so some of the limitations and disclaimers above may not apply
        to you. To the extent applicable law does not permit such disclaimer of
        warranty or limitation of liability, the scope and duration of such
        warranty and the extent of such liability shall be the minimum permitted
        under such applicable law.
      </span>
    </p>
    <p>
      <span>
        You agree to indemnify and hold harmless Epion, its licensors, and their
        respective officers, directors, employees, and agents for any violation
        by you of the terms of this agreement.
      </span>
    </p>
    <p>
      <span>
        YOU ACKNOWLEDGE THAT THE DISCLAIMERS, LIMITATIONS OF LIABILITY, AND
        INDEMNIFICATION PROVISIONS IN THIS AGREEMENT ARE A MATERIAL INDUCEMENT
        AND CONSIDERATION FOR THE GRANT OF THE LICENSE CONTAINED IN THIS
        AGREEMENT AND FOR PROVIDING YOU WITH ACCESS TO THE FEATURES.
      </span>
    </p>
    <p>
      <span>
        This agreement will be binding upon and inure to the benefit of the
        parties and their successors and assigns. Except as otherwise expressly
        provided herein, this agreement constitutes the entire agreement between
        the parties concerning the subject matter hereof. No prior or
        contemporaneous representations, inducements, promises, or agreements,
        oral or otherwise, between the parties with reference thereto will be of
        any force or effect. The failure of either party at any time to require
        performance by the other party of any provision of this agreement shall
        in no way affect the right of such party to require performance of that
        provision. Any waiver by either party of any breach of this agreement
        shall not be construed as a waiver of any continuing or succeeding
        breach of such provision, a waiver of the provision itself, or a waiver
        of any right under this agreement. If any provision of this agreement
        is ruled wholly or partly invalid or unenforceable by a court or other
        body of competent jurisdiction, the validity and enforceability of all
        provisions of this agreement not ruled to be invalid or unenforceable
        will be unaffected and the provision held wholly or partly invalid or
        unenforceable shall be deemed amended, and the court or other body is
        authorized to reform the provision, to the minimum extent necessary to
        render them valid and enforceable in conformity with the parties’ intent
        as manifested herein.
      </span>
    </p>
    <p>
      This Agreement shall be construed and enforced in accordance with the laws
      of the State of New Jersey (other than its conflicts of law provisions)
      and venue shall be exclusively in the federal or state courts sitting in
      such state.
    </p>
    <p>
      You may not assign this agreement or any of the rights or licenses granted
      under this agreement. Any attempted sublicense, transfer, or assignment
      in violation of this agreement is void.
    </p>
  </div>
);

export default TermsOfUseEpion;
