import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PropTypes from "prop-types";

const ItemsList = ({ items }) => (
  <List>
    {items.map((item) => (
      <ListItemButton
        component="button"
        disableGutters
        key={item.text}
        onClick={item.onClick}
        sx={{ width: "100%", borderBottom: "1px solid", borderColor: "grey.400" }}
      >
        <ListItemText primary={item.text} sx={{ color: "primary.main" }} />
        <ChevronRightIcon />
      </ListItemButton>
    ))}
  </List>
);

ItemsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ItemsList;
