import HeaderControl from "./Components/HeaderControl";
import TextFieldControl from "./Components/TextFieldControl";
import SelectControl from "./Components/SelectControl";
import CheckboxGroupControl from "./Components/CheckboxGroupControl";
import NumberControl from "./Components/NumberControl";
import SectionDividerControl from "./Components/SectionDividerControl";
import RadioButtonGroupControl from "./Components/RadioButtonGroupControl";
import ConsentTextControl from "./Components/ConsentTextControl";
import PainDiagramControl from "./Components/PainDiagramControl";

const components = {
  "checkbox-group": CheckboxGroupControl,
  divider: SectionDividerControl,
  header: HeaderControl,
  number: NumberControl,
  painDiagram: PainDiagramControl,
  "radio-group": RadioButtonGroupControl,
  select: SelectControl,
  text: TextFieldControl,
  textEditor: ConsentTextControl,
};

export default components;
