import forEach from "lodash/forEach";
import flatObjectAndDashKeys from "./flatObjectAndDashKeys";

/**
 * Exports a set of useful variables as css variables
 */
const setCssVariables = (muiTheme, container) => {
  const cssVariablesObject = {};

  setRootCssVariables(muiTheme, cssVariablesObject);
  setBackwardsCompatibleCssVariables(muiTheme, cssVariablesObject);
  setPalette(muiTheme, cssVariablesObject);
  setCSSVariablesFromObject(cssVariablesObject, container);
};

export default setCssVariables;

const setCSSVariablesFromObject = (variablesObject, container) => {
  forEach(variablesObject, (value, propertyName) => {
    (container || document.documentElement).style.setProperty(`--${propertyName}`, value);
  });
};

const setRootCssVariables = (theme, cssVariablesObject) => {
  const variables = theme.root?.cssVariables || {};

  Object.keys(variables).forEach((variable) => {
    cssVariablesObject[variable] = variables[variable];
  });
};

const setBackwardsCompatibleCssVariables = (theme, cssVariablesObject) => {
  cssVariablesObject["primary-font"] = theme.typography.fontFamily;
  cssVariablesObject["primary-color"] = theme.palette.basePrimary;
  cssVariablesObject["secondary-color"] = theme.palette.baseSecondary;
  cssVariablesObject["primary-gray-color"] = theme.palette.baseTertiary;
};

const setPalette = (theme, cssVariablesObject) => {
  const { palette } = theme;

  return Object.assign(cssVariablesObject, flatObjectAndDashKeys({ palette }));
};
