import xor from "lodash/xor";
import initTranslationsHelper from "utils/initTranslationsHelper";
import CheckboxGroup from "components/CheckboxGroup";
import { eligibleForSubmissionPropTypes } from "../propTypes";

const translate = initTranslationsHelper("universal.custom_forms.checkbox_group_control");

export const REQUIRED_OPTIONS = {
  allRequired: "allRequired",
  atLeastOneRequired: "required",
  notRequired: "notRequired",
};

const CheckboxGroupControl = ({ schema, onChange, error, leftAlign, value: selectedValues, disabled }) => {
  const { label, name, attrRequired, values: options } = schema;

  const required = isRequired(attrRequired);

  const checkboxGroupOptions = options.map((option) => ({
    label: option.label,
    value: option.value,
    checked: selectedValues.includes(option.value),
    name,
  }));

  const handleChange = (e) => {
    const optionValue = e.target.value;
    const newSelectedValues = toggleValueInArray(selectedValues, optionValue);
    onChange(newSelectedValues);
  };

  return (
    <CheckboxGroup
      disabled={disabled}
      error={error}
      label={label}
      leftAlign={leftAlign}
      onChange={handleChange}
      options={checkboxGroupOptions}
      required={required}
    />
  );
};

CheckboxGroupControl.eligibleForSubmission = true;

CheckboxGroupControl.getValueFromSchema = ({ values: options }) =>
  options.filter((option) => option.selected).map((option) => option.value);

CheckboxGroupControl.getUpdatedSchemaFromValue = (schema, selectedValues) => {
  const { values: options } = schema;
  const optionsUpdated = options.map((optionValue) => ({
    ...optionValue,
    selected: selectedValues.includes(optionValue.value),
  }));

  return {
    ...schema,
    values: optionsUpdated,
  };
};

CheckboxGroupControl.validate = (schema, selectedValues) => {
  const { attrRequired } = schema;

  if (REQUIRED_OPTIONS.allRequired === attrRequired && someAreNotSelected(schema, selectedValues)) {
    return translate("error_all_required");
  }

  if (REQUIRED_OPTIONS.atLeastOneRequired === attrRequired && noneAreSelected(schema, selectedValues)) {
    return translate("error_at_least_one_required");
  }

  return undefined;
};

CheckboxGroupControl.propTypes = eligibleForSubmissionPropTypes;

export default CheckboxGroupControl;

const isRequired = (attrRequired) =>
  attrRequired === REQUIRED_OPTIONS.allRequired || attrRequired === REQUIRED_OPTIONS.atLeastOneRequired;

const someAreNotSelected = (schema, selectedValues) =>
  schema.values.some((optionValue) => !selectedValues.includes(optionValue.value));

const noneAreSelected = (schema, selectedValues) =>
  !schema.values.some((optionValue) => selectedValues.includes(optionValue.value));

const toggleValueInArray = (arr, value) => xor(arr, [value]);
