/**
***************************************************************
***************************************************************
** IF YOU MODIFY THE CONTENT OF THIS DOCUMENT, PLEASE UPDATE **
** THE VERSION NUMBER FOR `epion_privacy_policy` in          **
** app/models/agreement.rb                                   **
***************************************************************
***************************************************************
*/
import Link from "@mui/material/Link";
import styles from "./styles.module.scss";

const PrivacyStatement = ({ privacyPolicyVersion, privacyPolicyStandaloneUrl }) => (
  <div className={styles.privacyStatement}>
    {
      privacyPolicyStandaloneUrl && (
        <p>
          This agreement can also be viewed, printed, and downloaded at:
          {" "}
          <Link href={privacyPolicyStandaloneUrl} rel="noreferrer" target="_blank">{privacyPolicyStandaloneUrl}</Link>
        </p>
    )
    }
    <div>
      <h1 className={styles.heading1}>PRIVACY POLICY</h1>
      <h2>{privacyPolicyVersion}</h2>
      <p>
        <em>Last Updated:</em>
        April 24, 2023
      </p>

      <p className={styles.emphatic}>
        THIS DOCUMENT CONSTITUTES THE PRIVACY POLICY OF EPION HEALTH, INC.
        (“EPION HEALTH”), A WHOLLY OWNED SUBSIDIARY OF KYRUUS, INC., WHICH
        PROVIDES CERTAIN SOLUTIONS TO OUR CUSTOMERS INCLUDING YOUR PROVIDER.
        PLEASE READ AND REVIEW THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND HOW
        EPION HEALTH TREATS YOUR PERSONAL INFORMATION AND WHAT CHOICES AND
        RIGHTS YOU HAVE AVAILABLE TO YOU. IF YOU DO NOT AGREE WITH THE TERMS
        AND CONDITIONS OF THIS POLICY, DO NOT ACCESS OR USE THE SITE THROUGH
        WHICH THIS POLICY IS BEING PRESENTED, THE EPION PLATFORM, OR ENGAGE IN
        COMMUNICATIONS WITH US. SHOULD YOU ACCESS OR USE THE SITE,
        THE PLATFORM, OR ENGAGE IN COMMUNICATIONS WITH US YOU WILL BE DOING SO
        AT YOUR OWN RISK. EPION HEALTH RESERVES THE RIGHT TO MAKE CHANGES TO
        THIS PRIVACY POLICY ANNUALLY AND/OR AS REQUIRED BY APPLICABLE LAW.
      </p>

      <p className={styles.emphatic}>
        THIS SITE, THE PLATFORM, AND OUR SERVICES ARE INTENDED FOR USERS
        LOCATED IN THE UNITED STATES. THIS SITE AND OUR SERVICES ARE NOT
        INTENDED FOR USERS LOCATED IN OTHER COUNTRIES, INCLUDING THE EUROPEAN
        UNION AND THE EUROPEAN ECONOMIC AREA. BY USING THE SITE OR THE
        PLATFORM, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE USING THE SITE OR THE
        PLATFORM FROM WITHIN THE UNITED STATES.
      </p>

      <h2 className={styles.heading2}>INTRODUCTION</h2>

      <p>
        Epion Health (also referred to herein as
        “
        <b className={styles.emphatic}>we</b>
        ,” “
        <b className={styles.emphatic}>us</b>
        ,” and “
        <b className={styles.emphatic}>our</b>
        ”) provides for or on behalf of your provider. We are committed to
        protecting the privacy and security of the personal information we
        collect, use, share, and otherwise process as part of our business. We
        believe in transparency and are committed to informing you about how we
        treat your personal information. This Policy will provide you with a
        description of our online and offline practices regarding your personal
        information and the rights you have regarding your personal
        information. You may obtain an accessible version of this Policy by
        contacting us via the methods identified in the “Contact Us” section of
        this Policy.
      </p>

      <h2 className={styles.heading2}>
        PERSONAL INFORMATION WE COLLECT AND PROCESS
      </h2>

      <p>
        We collect and process personal information via the methods described
        below. When providing information, please only provide your personal
        information. Do not provide another individual’s personal information
        to us, unless you are the legally authorized proxy for that person and
        for this specific purpose. You acknowledge that you are solely
        responsible for determining whether or not you have the right to
        provide any of this information. Certain personal information collected
        during the Epion Check-In or Epion PreVisit user experience will be
        automatically updated via our Platform to your electronic health record
        maintained by your Provider.
      </p>

      <table className={styles.standardTable}>
        <tbody>
          <tr>
            <th>
              <b className={styles.emphatic}>Method of Interaction:</b>
              {" "}
              Description of Practices
            </th>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>The Platform:</b>
              {" "}
              We collect and process personal information via Epion Check-In or
              Epion PreVisit (the
              {" "}
              <b className={styles.emphatic}>“Platform”</b>
              ) in two ways:
              <ul>
                <li>
                  When we provide check-in services for a customer (your
                  provider) via the Platform, we act as a “Business Associate”
                  under the Health Insurance Portability and Accountability Act
                  of 1996 (“HIPAA”). In that capacity, we collect, receive,
                  maintain, use, and disclose Protected Health Information
                  (“PHI”) as permitted or required under applicable law, and
                  our customer’s (your provider’s) privacy notice, privacy
                  policy, and terms of use control how we collect and process
                  PHI.
                </li>
                <li>
                  We also give individuals an opportunity to receive
                  personalized information about health-related products and
                  services that may be of interest to them on the Platform. We
                  do not act as a Business Associate in this capacity. This
                  service is provided on behalf of the individuals who choose
                  to share data with us pursuant to a HIPAA Authorization. See
                  “PERSONAL INFORMATION WE COLLECT AND PROCESS VIA THE
                  PLATFORM” to learn more about how we collect and process
                  personal information on the Platform in this capacity.
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Other Ways We Interact with You:
              </b>
              {" "}
              See “PERSONAL INFORMATION WE COLLECT AND PROCESS VIA OTHER WAYS”
              to learn more about how we collect and process personal
              information in other ways.
            </td>
          </tr>
        </tbody>
      </table>

      <p className={styles.sectionHeader}>
        <span className={styles.inlineHeader}>
          How We Use Personal Information
        </span>
        . To the extent permitted by applicable
        law, your personal information may be used to:
      </p>

      <ul>
        <li>Operate our business;</li>
        <li>Honor applicable Terms of Use, privacy policy, and contracts;</li>
        <li>Provide our products and services;</li>
        <li>
          Ensure the privacy and security of our Site, Platform, and services;
        </li>
        <li>Maintain our databases and back-ups;</li>
        <li>Manage our relationships with you;</li>
        <li>Communicate with you;</li>
        <li>Keep records of our communications with you;</li>
        <li>Send you notifications and newsletters;</li>
        <li>Promote our products and services to you;</li>
        <li>Contact you about other products and services;</li>
        <li>Enhance our marketing efforts;</li>
        <li>Operate the Site and the Platform;</li>
        <li>Analyze use of our Site, Platform, and services;</li>
        <li>Serve you the content and functionality you request;</li>
        <li>Develop new products and services;</li>
        <li>Track visits to the Site and the Platform;</li>
        <li>Enhance your experience;</li>
        <li>
          Provide you with a more personal and interactive experience on the
          Site and Platform;
        </li>
        <li>Process payments or other transactions;</li>
        <li>Comply with federal, state, or local laws;</li>
        <li>
          Comply with a civil, criminal, or regulatory inquiry, investigation,
          subpoena, or summons by federal, state, or local authorities;
        </li>
        <li>
          Cooperate with law enforcement agencies concerning conduct or activity
          that we, a service  provider, or a third party reasonably and in good
          faith believe may violate federal, state, or local law;
        </li>
        <li>Exercise or defend legal claims; and</li>
        <li>
          Collect, use, retain, sell, or disclose consumer information that is
          deidentified or aggregated under applicable law.
        </li>
      </ul>

      <p>
        <span className={styles.inlineHeader}>
          How We Share and Disclose Personal Information.
          {" "}
        </span>
        To the extent permitted by applicable law, your personal information
        may be shared in the following contexts:
      </p>

      <table className={styles.standardTable}>
        <tbody>
          <tr>
            <th>
              <b className={styles.emphatic}>Category:</b>
              {" "}
              Disclosure Contexts
            </th>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>Corporate Affiliates:</b>
              {" "}
              We may share your personal information with our corporate
              subsidiaries and affiliates and with their respective officers,
              directors, employees, contractors, accountants, attorneys, and
              agents.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Acquisitions and Similar Transactions:
              </b>
              {" "}
              We may disclose your personal information in the event of a
              merger, divestiture, restructuring, reorganization, dissolution,
              or other sale or transfer of some or all of our company assets,
              whether as a growing concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which personal information
              held by us is among the assets transferred.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>Disclosures with Your Consent:</b>
              {" "}
              We may ask if you would like us to share your personal information
              with other unaffiliated third parties who are not described
              elsewhere in this Policy. We will only disclose your personal
              information, in this context, with your consent.
              {" "}
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>Legal Obligations and Rights:</b>
              {" "}
              We may disclose your personal information in response to
              subpoenas, warrants, court orders or other legal processes, or to
              comply with relevant laws. We may also share your personal
              information in order to establish or exercise our legal rights,
              defend against a legal claim, and investigate, prevent, or take
              action regarding possible illegal activities, suspected fraud,
              safety of person or property, or a violation of our contract.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>Public:</b>
              {" "}
              Some areas of our Site may offer an opportunity for users to post
              comments or reviews in a public forum. Please remember that any
              information that is disclosed in these areas becomes public
              information, and you should exercise caution when deciding to
              disclose your personal information. If you decide to submit your
              personal information in these public areas, you do so at your own
              risk and acknowledge that the information will be publicly
              available.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>Service Providers:</b>
              {" "}
              We may share your personal information with our service providers
              that need access to your information to provide operational or
              other support services on our behalf or on behalf of our
              customers. Among other things, service providers help us to
              administer the Site/Platform; support our provision of
              services/products requested by you; provide technical support;
              send marketing, promotions, and communications to you about our
              services/products; provide payment processing; and assist with
              other legitimate purposes as permitted by law.
              {" "}
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>Professional Advisors:</b>
              {" "}
              We may share your personal information with our insurers and other
              professional advisors, including but not limited to attorneys and
              accountants that need access to your information to provide
              operational and/or other support services on our behalf.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Deidentified or Aggregated Data:
              </b>
              {" "}
              We may disclose aggregated information about our users and
              information that does not identify any specific individual, such
              as groupings of demographic data and customer preferences, for new
              product and marketing development.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>Third Parties:</b>
              {" "}
              We may provide personal information about you to third parties
              that may offer products and services specifically requested by
              you.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 className={styles.heading2}>
        STORAGE AND USE YOUR PERSONAL INFORMATION
      </h2>

      <p>
        We retain and use your personal information for as long as is necessary
        to fulfill the purposes for which it was collected, to comply with our
        business requirements and legal obligations, to resolve disputes, to
        protect our assets, to provide our products and services, and to
        enforce our agreements.
      </p>

      <p>
        To the extent permitted by law, we may retain and use anonymous and
        aggregated information for performance reporting, benchmarking, and
        analytic purposes and for product and service improvement. When we
        delete personal information, it will be removed from our active servers
        and databases. Your information may remain in our archives when it is
        not practical or possible to delete it.
      </p>

      <p>
        We take reasonable steps to delete the personal information we collect
        when (1) we have a legal obligation to do so, (2) we no longer have a
        purpose for retaining the information, and (3) if you ask us to delete
        your information, unless we determine that doing so would violate our
        existing, legitimate legal, regulatory, dispute resolution,
        contractual, or similar obligations. We may also decide to delete your
        personal information if we believe it is incomplete, inaccurate, or
        that our continued storage of your personal information is contrary to
        our legal obligations or business objectives.
      </p>

      <h2 className={styles.heading2}>
        PROTECTING YOUR PERSONAL INFORMATION?
      </h2>

      <p>
        When you transmit information to or from our Site or Platform, you do
        so at your own risk. We have security measures in place to protect the
        personal information that you share with us from being accidentally
        lost, used, altered, disclosed, or accessed in an unauthorized manner.
        We review our security procedures to consider appropriate new
        technology and methods on an annual basis.
      </p>

      <p>
        We use secure sockets layer (SSL) technology to encrypt data in
        transit. We also encrypt data at rest on our systems. We do not store
        or save your financial information. While our security measures seek to
        protect your personal information in our possession, no security system
        is perfect, and no data transmission over the Internet can be 100%
        secure. As a result, while we strive to protect your personal
        information, we cannot guarantee or warrant the security of any
        information you transmit to or from our Site or Platform in any and all
        circumstances.
      </p>

      <p>
        If a data breach compromises your personal information, you and any
        applicable regulator will be notified as per applicable legal
        requirements.
      </p>

      <h2 className={styles.heading2}>
        RIGHTS AND CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION
      </h2>

      <p>
        If you would like to manage, change, limit, or delete your personal
        information, or no longer want to receive any email, postal mail,
        telephone communications, including short message service (“SMS”) text
        messaging, from us in the future, such requests may be submitted via
        the “Contact Us” details at the end of this Policy. If you opt to
        provide certain information specific to an Epion Health product or
        solution, there will be a reasonable opportunity to opt-out prior
        to/and or at the time of submission of the same. If you are exercising
        a right that is the responsibility of our customers (your provider), we
        will direct you to contact the appropriate customer (your provider) who
        is responsible for responding to your request.
      </p>

      <p>
        <span className={styles.inlineHeader}>Email Opt-Out</span>
        . If you no longer wish to receive communications from us via email, you
        may opt-out by clicking the “Unsubscribe” link at the bottom of our
        email communications or by contacting us at
        {" "}
        <Link href="mailto:privacy@epionhealth.com">
          privacy@epionhealth.com
        </Link>
        . Once we receive your instruction, we will
        take corrective action.
      </p>

      <p>
        <span className={styles.inlineHeader}>Online Tracking Signals</span>
        . Except as otherwise required by law, we do not recognize
        browser settings or signals of tracking preferences, which may include
        “Do Not Track” instructions. We adhere to the standards set out in this
        Policy and therefore do not respond to “Do Not Track” signals on the
        Site/Platform, third-party websites, or online services where we may
        collect information.
      </p>

      <p>
        <span className={styles.inlineHeader}>
          Accuracy and Updating Your Personal Information
        </span>
        . Our goal is to keep your personal information accurate, current, and
        complete. In the event of any changes to your personal information,
        please let us know via the “Contact Us” details at the end of this
        Policy. If you become aware that inaccurate personal information about
        you is being stored, it is advised that you update your information. We
        are not responsible for any losses arising from any inaccurate,
        inauthentic, illegal, inappropriate, deficient, or incomplete personal
        data that you provide to us.
      </p>

      <p>
        <span className={styles.inlineHeader}>Preferences</span>
        . If you wish to change your communication preferences or ask that we
        restrict how we use your personal information, please contact us via the
        “Contact Us” details at the end of this Policy. You may also follow
        opt-out links on any marketing communications sent to you.
      </p>

      <p>
        <span className={styles.inlineHeader}>Complaints</span>
        . If you believe that your rights relating to your personal information
        have been violated, you may lodge a complaint with us by contacting us
        via the “Contact Us” details at the end of this Policy.
      </p>

      <p>
        <span className={styles.inlineHeader}>California Residents</span>
        . If you are a resident of California, you may review our California
        Resident Privacy Notice supplement at the end of this Policy for
        additional information and disclosures regarding rights of California
        residents.
      </p>

      <p>
        <span className={styles.inlineHeader}>Nevada Residents</span>
        . Effective October 1, 2019, you may submit a verified request to us at
        {" "}
        <Link href="mailto:privacy@epionhealth.com">
          privacy@epionhealth.com
        </Link>
        {" "}
        to request that we not make any sale (as defined under Nevada law) of
        any covered information (as defined under Nevada law) that we have
        collected or will collect about you. Please provide your name and up to
        date contact information in your request. We will respond to your
        request in accordance with Nevada law.
      </p>

      <h2 className={styles.heading2}>THIRD-PARTY SITES</h2>

      <p>
        This Policy does not apply to any third-party websites. This Policy is
        only applicable to the Site and the Platform.
      </p>

      <p>
        The Site and the Platform may contain links to media and other content
        from third-party websites. These links are to external, third-party
        websites which may be subject to their own privacy policies. Because of
        the dynamic media capabilities of the Site and the Platform, it may not
        be clear to you which links are related to the Site/Platform and which
        are to external, third-party websites. If you click on an embedded
        third-party link, you will be redirected away from the Site/Platform to
        the external third-party website. You can check the URL to confirm that
        you have left this Site. Any personal information provided by you or
        automatically collected from you by a third party will be governed by
        that party’s privacy policy and terms of use.
      </p>

      <p>
        We cannot and do not (1) guarantee the adequacy of the privacy and
        security practices employed by or the content and media provided by any
        third parties or their websites, (2) control third parties’ independent
        collection or use or your personal information, or (3) endorse any
        third-party information, products, services, or websites that may be
        reached through embedded links on this Site and the Platform.
      </p>

      <h2 className={styles.heading2}>
        CHILDREN’S ONLINE PRIVACY PROTECTION ACT
      </h2>

      <p>
        The Children’s Online Privacy Protection Act (“COPPA”), along with
        other data privacy regulations, restrict the collection, use, or
        disclosure of personal information from and about children on the
        Internet. Our Site/Platform and services are not directed to children
        under the age of 16, nor is information knowingly collected from
        children under the age of 16. No one under the age of 16 may access,
        browse, or use the Site/Platform or provide any information to or on
        the Site/Platform. If we learn that we have collected or received
        personal information from a child under the age of 16 without parent or
        legal guardian consent, we will take steps to stop collecting that
        information and delete it. If you believe we might have any information
        from or about a child under the age of 16, please contact us using the
        “Contact Us” information provided below.
      </p>

      <p>
        For more information about COPPA, please visit the Federal Trade Commission’s website at:
        {" "}
        <Link
          href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule"
          rel="noopener noreferrer"
          target="_blank"
        >
          { /* eslint-disable-next-line max-len */ }
          https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule
        </Link>
      </p>

      <h2 className={styles.heading2}>UPDATES AND CHANGES</h2>

      <p>
        We reserve the right, at any time, to add to, change, update, or modify
        this Policy to reflect any changes to the way in which we treat your
        personal information or in response to changes in law. Should this
        Policy change, we will post a notice showing all changes made on this
        page. If we make material changes to how we treat your personal
        information, we will also notify you by posting a notice on the home
        page of the Site and within the Platform for a reasonable period of
        time. Any such changes, updates, or modifications shall be effective
        immediately upon posting. The date on which this Policy was last
        modified is identified at the beginning of this Policy.
      </p>

      <p>
        You are expected to, and you acknowledge and agree that it is your
        responsibility to carefully review this Policy prior to using the Site
        and/or the Platform, so that you are aware of any changes. Your
        continued use of the Site or the Platform after the “Last Updated” date
        will constitute your acceptance of and agreement to such changes and to
        our collection and sharing of your personal information according to
        the terms of the then-current Policy. Do not access, view, or use any
        part of the Site or the Platform if you do not agree with this Policy
        and/or our practices.
      </p>

      <h2 className={styles.heading2}>CONTACT US</h2>

      <p>
        For more information, or if you have any questions or concerns regarding
        this Policy, wish to exercise your rights, or wish to lodge a complaint
        with us, you may contact us using the information below. Please note,
        if your communication is sensitive, you may wish to contact us by
        postal mail.
      </p>

      <p>
        <b className={styles.emphatic}>In Writing</b>
        : Kyruus. Inc. - 100 Franklin Street, 8th Floor, Boston, MA 02210
      </p>

      <p>
        <b className={styles.emphatic}>By Email</b>
        :
        {" "}
        <Link href="mailto:privacy@epionhealth.com">
          privacy@epionhealth.com
        </Link>
      </p>

      <h2 className={styles.heading2}>
        PERSONAL INFORMATION WE COLLECT AND PROCESS VIA THE PLATFORM
      </h2>

      <p>
        When we are providing check-in services for a “Covered Entity” customer
        (your provider) via the Platform, we act as a “Business Associate”
        under the Health Insurance Portability and Accountability Act of 1996
        (“HIPAA”). In this capacity, we collect, receive, maintain, use, and
        disclose Protected Health Information as permitted or required under
        applicable law, and our Covered Entity customer’s (your provider)
        privacy notice, terms of use, and privacy policy control how we collect
        and process PHI.
      </p>

      <p>
        We also give individuals an opportunity to receive personalized
        information about health-related products and services that may be
        relevant to them on the Platform. We do not act as a Business Associate
        in this capacity, as the service is provided on behalf of the
        individuals who choose to share data with us pursuant to a HIPAA
        Authorization. When an individual opts-in to receiving this information
        via the Platform, we receive and process the following personal
        information:
      </p>

      <table className={styles.standardTable}>
        <tbody>
          <tr>
            <th>
              <b className={styles.emphatic}>Category:</b>
              {" "}
              Description and Purpose
            </th>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                End User (Patient) Medical Information:
              </b>
              {" "}
              If you, as an end user of our Platform, direct your health care
              provider to share your medical information with us, we will
              receive the information provided by your healthcare provider.
              This may include but is not limited to the health information
              entered during the check-in process or on file with your
              healthcare provider.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Cookies and Similar Technologies:
              </b>
              {" "}
              When you use the Platform, we collect cookies and use similar
              technologies as described in the “Personal Information Collected
              Via Cookies and Similar Technologies” section below.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>Usage Information:</b>
              {" "}
              When you use the Platform, we automatically collect data from the
              browser and device, which include the date and time of your visit
              as well as your location, Internet Protocol (IP) address or
              unique device identifier, domain server, browser type, access
              time, and information about which pages you visit.
            </td>
          </tr>
        </tbody>
      </table>

      <p><span className={styles.inlineHeader}>&nbsp;</span></p>

      <h4 className={styles.heading4}>
        Personal Information Collected Via Cookies and Similar Technologies
      </h4>

      <span className={styles.inlineHeader}>
        <u>First and Third-Party Cookies:</u>
      </span>

      <p>
        A “cookie” is a small file created by a web server that can be stored
        on your device (if allowed) for use either during a particular browsing
        session (a “session” cookie) or a future browsing session (a
        “persistent” cookie). “Session” cookies are temporarily stored on your
        hard drive and only last until they expire at the end of your browsing
        session. “Permanent” cookies remain stored on your hard drive until
        they expire or are deleted by you. Local stored objects (or “flash”
        cookies) are used to collect and store information about your
        preferences and navigation to, from, and on a website.
      </p>

      <p>
        First-party cookies are set by the Platform, and they can only be read
        via the Platform. Third-party cookies are set by a party other than us.
        We use first-party and third-party session, persistent, and/or flash
        cookies and the information collected by them as set forth herein.
      </p>

      <span className={styles.inlineHeader}>
        <u>Similar Technologies:</u>
      </span>

      <p>
        In addition to cookies, there are other automatic data collection
        technologies, such as Internet tags, web beacons (clear gifs, pixel
        tags, and single-pixel gifs), and navigational data collection (log
        files, server logs, etc.) that can be used to collect data as users
        navigate through and interact with the Platform:
      </p>

      <ul>
        <p>
          <li>
            <u>Web beacons</u>
            : These are tiny graphics (sometimes called “clear GIFs” or “web
            pixels”) with unique identifiers that are used to understand
            browsing activity. In contrast to cookies, which are stored on a
            user’s computer hard drive, web beacons are rendered invisible on
            web pages when you open a page.
          </li>

          <li>
            <u>Social Widgets</u>
            : These are buttons or icons provided by third-party social media
            providers that allow you to interact with social media services
            when you view a webpage or mobile app screen. These social widgets
            may collect browsing data, which may be received and controlled by
            the third party that provided the widget.
          </li>

          <li>
            <u>UTM Codes</u>
            : These are strings that can appear in a URL (the “Uniform Resource
            Locator,” which is typically an http or https address entered to
            go to a web page) when you move from one web page or website to
            another. The string can represent information about browsing, such
            as which advertisement, page, or publisher sent the user to the
            receiving website.
          </li>
        </p>
      </ul>

      <h4 className={styles.heading4}>
        What Cookies and Similar Technologies Are in Use and Why Do We Use
        Them?
      </h4>

      <table className={styles.standardTable}>
        <tbody>
          <tr>
            <th>
              <b className={styles.emphatic}>Domain</b>
              {" "}
              : Cookie Name : Description : Duration
            </th>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>epionhealth.com</b>
              {" "}
              : epion_device_password : Unique device identifier for the Epion
              Platform : permanent
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>epionhealth.com</b>
              {" "}
              : _patient-check-in_session : Unique session identifier for the
              Epion Platform : permanent
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>epionhealth.com</b>
              {" "}
              : rack.session : Unique session identifier for the Epion Platform
              : permanent
            </td>
          </tr>
        </tbody>
      </table>

      <span className={styles.inlineHeader}>
        <u>Other Third-Party Technologies</u>
      </span>

      <p>
        Some third parties may use automated data collection technologies to
        collect information about you when you browse the Internet. The
        information they collect about your online browsing activities over time
        and across different websites and other online services may be
        associated with your personal information and used to provide you with
        targeted content. We do not control any third-party technologies in any
        capacity. If you have any questions related to targeted content, you
        should contact the responsible third-party directly or consult their
        privacy policies.
      </p>

      <h2 className={styles.heading2}>
        PERSONAL INFORMATION WE COLLECT AND PROCESS VIA OTHER WAYS
      </h2>

      <p>
        Outside of our Site and Platform, we may collect, receive, and process
        personal information from you via the methods described below.
      </p>

      <table className={styles.standardTable}>
        <tbody>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Your Communications and Feedback
              </b>
              : When you communicate with or provide feedback to us, we receive
              and may retain your communications and the information included
              in those communications. If you receive email communications from
              us, we may use certain tools to capture data related to if/when
              you open our message and if/when you click on any links or
              banners it contains. Other information collected through this
              email tracking feature may include: your email address, the date
              and time of you opening the email, a message number, the name of
              the list from which the message was sent, a tracking URL number,
              and a destination page. This information is used to enhance our
              marketing efforts.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Financial and Payment Information
              </b>
              : If you decide to pay fees related to your appointment or make
              other payments to your provider through this Site or Platform,
              you may be asked to provide your bank account number, bank
              routing information, and other data necessary to process
              payments, including credit card numbers, security codes,
              expiration dates, and other related billing information. This
              information is passed directly to our payment processors and is
              not accessed by us.
              <br />
              <br />
              By submitting your payment card information, you expressly consent
              to the sharing of your information with third-party payment
              processors and other third-party services (including but not
              limited to vendors who provide fraud detection services to us and
              other third parties). Please note that credit card numbers and
              account information are not stored on our servers.
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Third-Party
                Sources
              </b>
              : We may also receive information about you from other sources,
              including third parties, business partners, our affiliates, or
              publicly-available sources.
            </td>
          </tr>
        </tbody>
      </table>

      <h2 className={styles.heading2}>CALIFORNIA RESIDENT PRIVACY NOTICE</h2>

      <p>
        This California Resident Privacy Notice supplements the information and
        disclosures contained in our Privacy Policy. It applies to individuals
        residing in California from whom we collect Personal Information as a
        business under the California Privacy Rights Act (California Civil Code
        §§ 1798.100 to 1798.199) and its implementing regulations, as amended
        or superseded from time to time (“CPRA”).
      </p>

      <h2 className={styles.heading2}>
        Data Practices During the Last 12 Months
      </h2>

      <p>
        The chart below provides the categories of Personal Information (as
        defined by the CPRA) we have collected, sold, shared, or otherwise
        disclosed or used for business or commercial purposes in the 12-month
        period that precedes the date this Privacy Policy is being viewed. The
        examples of Personal Information provided for each category reflect
        each category’s statutory definition and may not reflect all of the
        specific types of Personal Information associated with each category.
        As mentioned in our privacy policy above, our Site/Platform and
        services are not directed to children under the age of 16, nor is
        information knowingly collected from children under the age of 16.
      </p>

      <p>
        Please note that we contract with each of the following service
        providers or contractors for data collection services: Amazon Web
        Services, Aptible, Google Cloud Platform, Looker, Mailgun, and Twilio.
        As a result, these service providers or contractors are authorized to
        collect Personal Information and information about our business
        practices through our website.
      </p>

      <table className={styles.standardTable}>
        <tbody>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Category of Personal Information
              </b>
            </td>
            <td>
              <b className={styles.emphatic}>
                Do We Collect?
              </b>
            </td>
            <td>
              <b className={styles.emphatic}>
                Do We Disclose? (See “Disclosure of Personal Information”
                Section Below)
              </b>
            </td>
            <td>
              <b className={styles.emphatic}>
                Do We Sell to or “Share” with Third Parties?  (See “Disclosure
                of Personal Information” Section Below)
              </b>
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Sensitive Personal Information
              </b>
              <br />
              <i>Examples</i>
              : Personal information that reveals a consumer’s social
              security, driver’s license, state identification card, or
              passport number; account log-in, financial account, debit card,
              or credit card number in combination with any required security
              or access code, password, or credentials allowing access to an
              account; precise geolocation (within 1,850 feet of the consumer);
              racial or ethnic origin, religious or philosophical beliefs, or
              union membership; mail, email, or text message contents (unless
              Organogenesis is the intended recipient of the communication); or
              genetic data. Sensitive personal information also includes the
              processing of biometric information for the purpose of uniquely
              identifying a consumer and personal information collected and
              analyzed concerning a consumer’s health, sex life, or sexual
              orientation.
            </td>
            <td>
              Yes
            </td>
            <td>
              Yes
            </td>
            <td>
              No
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Identifiers
              </b>
              <br />
              <i>Examples</i>
              : Name, alias, postal address, unique personal identifier, online
              identifier, internet protocol address, email address, account
              name, or other similar identifiers.
            </td>
            <td>
              Yes
            </td>
            <td>
              Yes
            </td>
            <td>
              Yes
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Categories of Personal Information in Cal. Civ. Code Section
                1798.80(e)
              </b>
              <br />
              <i>Examples</i>
              : Name, signature, physical characteristics or description,
              address, telephone number, insurance policy number, education,
              employment, employment history, bank account number, credit card
              number, or debit card number (to the extent not already
              identified as Sensitive Personal Information), or any other
              financial information, medical information, or health insurance
              information.
            </td>
            <td>
              Yes
            </td>
            <td>
              Yes
            </td>
            <td>
              Yes
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Characteristics of Protected Classifications under California
                or Federal Law
              </b>
              <br />
              <i>Examples</i>
              : To the extent not already identified as Sensitive Personal
              Information, race or color, ancestry or national origin, religion
              or creed, age (over 40), mental or physical disability, sex
              (including gender and pregnancy, childbirth, breastfeeding or
              related medical conditions), sexual orientation, gender identity
              or expression, medical condition, genetic information, marital
              status, military and veteran status.
            </td>
            <td>
              Yes
            </td>
            <td>
              Yes
            </td>
            <td>
              Yes
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Commercial Information
              </b>
              <br />
              <i>Examples</i>
              : Records of personal property, products or services purchased,
              obtained, or considered, or other purchasing or consuming
              histories or tendencies.
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Biometric Information
              </b>
              <br />
              <i>Examples</i>
              : To the extent not already identified as Sensitive Personal
              Information, physiological, biological, or behavioral
              characteristics, including information pertaining to DNA, that
              can be used, is, or is intended to be used singly or in
              combination with each other or with other identifying data, to
              establish individual identity, such as imagery of the iris,
              retina, fingerprint, face, hand, palm, vein patterns, and voice
              recordings, from which an identifier template, such as a
              faceprint, a minutiae template, or a voiceprint, can be
              extracted, and keystroke patterns or rhythms, gait patterns or
              rhythms, and sleep, health, or exercise data that contain
              identifying information.
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Internet or Other Electric Network Activity Information
              </b>
              <br />
              <i>Examples</i>
              : Browsing history, search history, and information regarding a
              consumer’s interaction with an internet website, application or
              advertisement.
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Geolocation Data
              </b>
              <br />
              <i>Examples</i>
              : Physical location (to the extent not already identified as
              Sensitive Personal Information).
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Sensory Information
              </b>
              <br />
              <i>Examples</i>
              : Audio, electronic, visual, thermal, olfactory, or similar
              information
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Professional or employment-related information
              </b>
              <br />
              <i>Examples</i>
              : If you apply for a job with us, job application or resume
              information, past and current job history, and job performance
              information.
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Non-Public Information (as defined in 20 U.S.C. 1232g; 34
                C.F.R. Part 99)
              </b>
              <br />
              <i>Examples</i>
              : Records that are directly related to a student maintained by an
              educational agency or institution or by a party acting for the
              agency or institution.
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
          </tr>
          <tr>
            <td>
              <b className={styles.emphatic}>
                Inferences Drawn from Personal Information
              </b>
              <br />
              <i>Examples</i>
              : Consumer profiles reflecting a consumer’s preferences,
              characteristics, psychological trends, predispositions, behavior,
              attitudes, intelligence, abilities, and aptitudes.
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
            <td>
              No
            </td>
          </tr>
        </tbody>
      </table>

      <h2 className={styles.heading2}>
        Categories of Sources
      </h2>
      <p>
        In the 12-month period that precedes the date this Privacy Policy is
        being viewed, we have collected Personal Information from the following
        categories of sources: Our Site and Platform, you and/or your devices,
        business partners and affiliates, other third-parties, and
        publicly-available sources.
      </p>

      <h2 className={styles.heading2}>
        Use of Personal Information
      </h2>

      <p>
        Upon collection, we will typically retain each category of your
        Personal Information listed above for the amount of time necessary to
        fulfill the purposes for which it was collected, consistent with all
        applicable law and our internal retention schedule. In general, as
        permitted by the CPRA, Personal Information may be used in a manner
        that is reasonably necessary and proportionate for any of the specific
        business and commercial purposes listed below:
      </p>

      <ol>
        <li>
          Performing and providing its regular services and goods.
        </li>
        <li>
          Preventing, detecting, and investigating security incidents that
          compromise the availability, authenticity, integrity, and
          confidentiality of stored or transmitted personal information.
        </li>
        <li>
          Resisting malicious, deceptive, fraudulent, or illegal actions and
          prosecuting those responsible for those actions.
        </li>
        <li>
          Ensuring the physical safety of natural persons.
        </li>
        <li>
          Short-term transient use, including non-personalized advertising
          shown as part of your current interaction with our customer (your
          provider) or with us, through the Site, the Platform, or otherwise.
        </li>
        <li>
          Performing services on behalf of our customer (your provider), such
          as maintaining or servicing accounts, providing customer service,
          processing or fulfilling orders and transactions, verifying customer
          information, processing payments, providing financing, providing
          analytic services, or providing storage.
        </li>
        <li>
          Verifying or maintaining the quality or safety of a service, product,
          or device that our customer (your provider) owns, manufactures, or
          controls, or improving, upgrading, or enhancing any such service,
          product, or device.
        </li>
        <li>
          Collecting or processing Sensitive Personal Information where the
          collection or processing is not for the purpose of inferring
          characteristics about you.
        </li>
      </ol>

      <p>
        To the extent that we collect Sensitive Personal Information from you,
        Sensitive Personal Information will only be used for purposes 1-8
        listed above / may be used for any purpose listed above, subject to
        your Right to Limit the Use and Disclosure of Sensitive Personal
        Information.
      </p>

      <h2 className={styles.heading2}>
        Disclosure of Personal Information
      </h2>

      <p>
        In the 12-month period that precedes the date this Privacy Policy is
        being viewed, we may have disclosed each category of Personal
        Information to the following classifications of third parties: (1)
        corporate parents, subsidiaries, and affiliates; (2) advisors
        (accountants, attorneys); (3) service providers (data analytics data
        storage, mailing, marketing, payment processing, Site and Platform
        administration, technical support); and (4) operating systems and
        platforms, (5) advertising networks (Internet or other electronic
        network activity information, geolocation data, and inferences only),
        (6) internet service providers, and (7) social networks (Internet or
        other electronic network activity information, Geolocation data, and
        Inferences only).
      </p>

      <p>
        As stated in the chart above, we do not sell any Personal Information
        to, or “share” any Personal Information with, any third-party. Under
        the CPRA, the term “share” specifically refers to the transfer of
        Personal Information to a third-party for cross-context behavioral
        advertising. We will only sell Personal Information to, or “share”
        Personal Information with, third-parties upon obtaining explicit
        consent from the consumer through the Platform.
      </p>

      <h2 className={styles.heading2}>
        Notice of Financial Incentive
      </h2>

      <p>
        We give individuals an opportunity to receive personalized information
        about health-related products and services that may be interesting to
        them via the Platform. Individuals who want to participate in this
        service will have the opportunity to instruct their health care
        providers to disclose data to us or a different provider of products
        and services pursuant to a HIPAA Authorization. Individuals can then
        choose to share information with our customer (your provider), with us,
        and/or with third-parties to receive products, services, discounts, or
        other offers or benefits. This service may involve the following
        categories of personal information under the CPRA:
      </p>

      <ul>
        <li>
          Identifiers
        </li>
        <li>
          Categories of personal information described in the California
          Customer Records statute
        </li>
        <li>
          Characteristics of protected classifications
        </li>
        <li>
          Commercial information
        </li>
        <li>
          Biometric information
        </li>
        <li>
          Internet or other electronic network activity information
        </li>
        <li>
          Geolocation data
        </li>
        <li>
          Audio, electronic, visual, thermal, olfactory, or similar information
        </li>
        <li>
          Professional or employment-related information
        </li>
        <li>
          Education information
        </li>
        <li>
          Inferences
        </li>
      </ul>

      <p>
        The financial incentive or price or service difference is reasonably
        related to the value provided by the consumer’s data, as data is
        required to facilitate the service. The value depends on how
        participating individuals interact with the service.
      </p>

      <p>
        <span className={styles.inlineHeader}>How to opt-in</span>
        . You may choose to receive personalized information about
        health-related products and services by opting-in on the Platform when
        the option is presented to you. You may choose to share your
        information with our customer (your provider), with us, and/or with
        third-parties by selecting that option and providing that authorization
        when presented to you on the Platform and or Site.
      </p>

      <p>
        <span className={styles.inlineHeader}>How to withdraw</span>
        . You have the right to withdraw from accessing this site, the
        Platform, and receiving our services at any time. You may exercise that
        right by contacting us via the “Contact Information” section of this
        Privacy Policy.
      </p>

      <h2 className={styles.heading2}>
        Your California Privacy Rights
      </h2>

      <p>
        If you are a California resident, you have the rights listed below,
        which may be exercised according to the instructions in the following
        section of this Privacy Policy.
      </p>

      <p>
        <b className={styles.emphatic}>Right to Know and Access</b>
        . Subject to certain exceptions, you have the right to know what
        Personal Information we have collected about you, including the
        categories of Personal Information, the categories of sources from
        which the Personal Information is collected, the specific business or
        commercial purpose for collecting, selling, or sharing the Personal
        Information, the categories of third-parties to whom we disclosed the
        Personal Information, and the specific pieces of Personal Information
        that we have collected about you. In responding to a Request to Know
        and Access, we will generally provide all Personal Information that we
        have collected and maintained about you since January 1, 2022, unless
        you request a specific timeframe.
      </p>

      <p>
        <b className={styles.emphatic}>Right to Delete</b>
        . Subject to certain exceptions, you have the right to request deletion
        of Personal Information about you that we have collected from you.
      </p>

      <p>
        <b className={styles.emphatic}>Right to Correct</b>
        . Subject to certain exceptions, you have the right to request
        correction of inaccurate personal information about you that we have
        collected from you and maintain.
      </p>

      <p>
        <b className={styles.emphatic}>Right to Opt-Out</b>
        . Consumers generally have the right to opt out of the sale or sharing
        of their Personal Information (i.e., prevent a business that collects
        their Personal Information from selling or sharing it). As stated in
        the chart above, we do not sell or “share” any Personal Information
        that we collect. Under the CPRA, the term “share” specifically refers
        to the transfer of Personal Information to a third-party for
        cross-context behavioral advertising.
      </p>

      <p>
        <b className={styles.emphatic}>
          Right to Limit Use and Disclosure of Sensitive Personal Information
        </b>
        . Subject to certain exceptions, which are listed in the Request Form
        (a link to which is included below), you generally have the right to
        prevent your Sensitive Personal Information from being used or
        disclosed for business and commercial purposes.
      </p>

      <h2 className={styles.heading2}>
        Exercising Your Rights
      </h2>

      <p>
        To submit a Request to Know and Access, Delete, or Correct your
        Personal Information or Limit the Use and Disclosure of Your Sensitive
        Personal Information, you may complete the form which is available
        through our
        {" "}
        <Link
          href="https://my.datasubject.com/16A16ZS25ZrF0fhH/107"
          rel="noreferrer"
          target="_blank"
        >
          Request Portal
        </Link>
        , or send us an email at
        {" "}
        <Link href="mailto:privacy@epionhealth.com">
          privacy@epionhealth.com
        </Link>
        . While we do not sell or share  (as those
        terms are defined under the CPRA) any Personal Information that we
        collect from you at this time, we will not treat user-enabled global
        privacy controls, such as a browser plugin or privacy setting, device
        setting, or other mechanism, that communicate or signal your choice to
        opt-out of the sale or sharing of your Personal Information as a valid
        opt-out request for that browser or device, or, if known, for you,
        individually.
      </p>

      <p>
        We will maintain records of consumer requests submitted under the CPRA,
        including how we responded to such requests, in accordance with the
        CPRA and all applicable law. We will use reasonable security measures
        when transmitting any Personal Information to you in connection with
        any consumer request submitted under the CPRA.
      </p>

      <p>
        <u>
          Verification Procedures
        </u>
      </p>

      <p>
        To determine whether the individual making the request is the consumer
        we have collected information from, we take reasonable steps to verify
        your identity by matching the identifying information provided by you
        in the request to the personal information that we already maintain
        about you. As a part of this process, you will be required to provide
        your name, address, telephone number, date of birth, and/or the name of
        the provider with which you last used the Site or the Platform.
      </p>

      <p>
        If we cannot verify your identity based on the information already
        maintained, we may request additional information from you. We will try
        to limit the information collected and we will only use this
        information to verify your identity and for security or
        fraud-prevention purposes. Except as required by law, we will delete
        any new personal information collected for the purposes of verification
        as soon as practical after processing the request.
      </p>

      <p>
        We may require different levels of authentication based upon the nature
        of the personal information requested. A more stringent verification
        process is applied when (1) Sensitive Personal Information is involved,
        (2) there is a greater risk of harm to the consumer, and/or (3) there
        is a higher likelihood that fraudulent or malicious actors would
        request the information.
      </p>

      <p>
        <u>
          Authorized Agents
        </u>
      </p>

      <p>
        If you use an authorized agent to submit a request to know or a request
        to delete, we may require you to: (1) provide the authorized agent with
        signed, written permission to do so; (2) verify your identity directly
        with us; and (3) directly confirm with us that you provided the
        authorized agent permission to submit the request. However, we will not
        require these actions if you have provided the authorized agent with
        power of attorney pursuant to the California Probate Code. We may deny
        a request from an agent that does not submit proof that they have been
        authorized by the consumer to act on their behalf.
      </p>

      <h2 className={styles.heading2}>
        CPRA Non-Discrimination
      </h2>

      <p>
        You have the right not to receive discriminatory treatment by us due to
        your exercise of the rights provided by the CPRA, including an
        employee’s, applicant’s, or independent contractor’s right not to be
        retaliated against for the exercise of their CPRA rights.
      </p>

      <h2 className={styles.heading2}>
        California Shine the Light
      </h2>

      <p>
        Under California Civil Code Section 1798.83, California residents who
        provide personal information in obtaining products or services for
        personal, family, or household use may be entitled to request and
        obtain from us once a calendar year information about the information
        we shared, if any, with other businesses for direct marketing uses.
        Please be aware that not all information sharing is covered by the
        “Shine the Light” requirements and only information on covered sharing,
        if any, will be included in our response. As part of the California
        Online Privacy Protection Act, all users of our Site may make any
        changes to their information at any time by contacting us at
        {" "}
        <Link href="mailto:privacy@epionhealth.com">
          privacy@epionhealth.com
        </Link>
        .
      </p>

      <h2 className={styles.heading2}>
        Do Not Track
      </h2>

      <p>
        There is currently no accepted standard on how to respond to Do Not
        Track signals, and we do not respond to such signals.
      </p>

      <h2 className={styles.heading2}>
        Contact Information
      </h2>

      <p>
        If you have any questions, comments, or concerns about this notice or
        our processing activities, or you would like to exercise your privacy
        rights, please email us at
        {" "}
        <Link href="mailto:privacy@epionhealth.com">
          privacy@epionhealth.com
        </Link>
        . Any person with a
        disability that prevents or restricts them from accessing this policy
        through our website may request a copy of the policy in an alternative
        format by contacting us at
        {" "}
        <Link href="mailto:privacy@epionhealth.com">
          privacy@epionhealth.com
        </Link>
        .
      </p>

      <p>
        <b className={styles.emphatic}>Effective Date</b>
        : January 1, 2023
      </p>

      <p>
        Notwithstanding the Effective Date of this California Resident Privacy
        Notice, please note that the above chart that lists the categories of
        Personal Information that we have collected, sold, shared, or otherwise
        disclosed or used for business or commercial purposes is applicable to
        the 12-month period that precedes the Effective Date.
      </p>

      <p>
        FH11286185.1
      </p>
    </div>
  </div>
);

export default PrivacyStatement;
