function getValuesFromSchema(schema, components) {
  const { controls } = schema;

  return controls.reduce((valuesSoFar, controlSchema) => {
    const { type, name } = controlSchema;
    const Component = components[type];
    if (!Component || !Component.eligibleForSubmission) {
      return valuesSoFar;
    }
    return {
      ...valuesSoFar,
      [name]: Component.getValueFromSchema(controlSchema),
    };
  }, {});
}

export default getValuesFromSchema;
